import { MainPage } from "./pages/MainPage";

function App() {
    return (
        <div>
            <MainPage />
        </div>
    );
}

export default App;
